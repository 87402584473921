import { ReactElement } from 'react'

import { Login } from '@/contents'
import { Main } from '@/layouts'
import { makeStaticProps } from '@/lib/getStatic'
import { NextPageWithLayout } from '@/pages/_app'

const LoginPage: NextPageWithLayout = () => <Login />

LoginPage.getLayout = function getLayout(page: ReactElement) {
  return <Main>{page}</Main>
}

export default LoginPage

const getStaticProps = makeStaticProps()
export { getStaticProps }
